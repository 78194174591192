import { render, staticRenderFns } from "./VNavLink.vue?vue&type=template&id=33239050&functional=true&"
import script from "./VNavLink.vue?vue&type=script&lang=js&"
export * from "./VNavLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports